
import { Component, Mixins } from "vue-property-decorator";
import { Tabbar, TabbarItem } from "vant";
import { Mixin } from "@/core/mixins/mixin";

@Component({
  name: "channelDistributionLayout",
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
})
export default class ChannelDistributionLayout extends Mixins(Mixin) {
  bottomTabName = "show";

  get isCustomTemplage(): boolean {
    return (
      this.$route.query.templateType == "custom" || this.$route.name == "custom"
    );
  }

  /**
   * 进入 MainLayout 时根据当前路由更新一下 bottomTabName
   * 有些情况不是通过点击 Tab 进入这三个页面的
   * 这时无法通过 van-tabbar 的 v-modle 更新 bottomTabName
   */
  mounted(): void {
    this.bottomTabName = this.$route.name == "mine" ? "mine" : "show";
    this.ChannelDistributionModule.SET_templateType_PERSIST(
      this.isCustomTemplage ? "custom" : "common"
    );
    this.ChannelDistributionModule.SET_distributionChannelId_PERSIST(
      String(this.$route.query.distributionChannelId || "")
    );
    this.ChannelDistributionModule.SET_distributionSeriesId_PERSIST(
      String(this.$route.query.distributionSeriesId || "")
    );
  }

  public icon = {
    active: "https://img01.yzcdn.cn/vant/user-active.png",
    inactive: "https://img01.yzcdn.cn/vant/user-inactive.png",
  };

  public menus = [
    {
      name: "show",
      title: "演出",
      active: require("@/assets/images/TabIcons/icon-show-active.png"),
      inactive: require("@/assets/images/TabIcons/icon-show.png"),
    },
    {
      name: "mine",
      title: "我的",
      active: require("@/assets/images/TabIcons/icon-mine-active.png"),
      inactive: require("@/assets/images/TabIcons/icon-mine.png"),
    },
  ];

  get style(): { paddingBottom: string } {
    return {
      paddingBottom: this.MenuModule.show ? "60px" : "10px",
    };
  }

  public menuClick(name: string): void {
    /**
     * 渠道分销，是否是自定义模板
     *
     * 因为 Layout 跨越了两个页面：【演出】和【我的】
     * 只有【演出】页需要模板类型的信息
     * 为了在【我的】页面返回【演出】页时能知道模板类型
     * 在去【我的】页时需要判断并带上 URL 参数，从【我的】回到【演出】时再通过该 URL 参数来决定往哪路由
     * 虽然可以用 session 域的缓存来做
     * 但是考虑到存在直接分享【我的】页面的场景，还是用 URL 参数来维护这个信息
     */
    let uriName = name;
    if (name == "show") {
      // 点击【演出】时根据参数判断是跳转到通用模板还是自定义模板
      uriName = this.isCustomTemplage ? "custom" : "common";
    }
    this.$router.push({ path: `/channeldistribution/${uriName}` });
  }
}
